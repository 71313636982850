<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="6" md="4">
        <div>
          <v-btn class="pa-8" icon @click="closeDetail()">
            <v-icon x-large color="primary">
              {{ icons.mdiArrowLeftThick }}
            </v-icon>
          </v-btn>
          <v-card class="mx-auto pt-5" :elevation="0" :style="'border: none !important'">
            <v-img
              class="img-avatar mx-auto"
              contain
              :src="this.contact.editedAvatar || this.contact.avatar"
              :lazy-src="defaultProfPic"
            ></v-img>
            <div class="mt-3 d-flex justify-center" :style="'color: black; font-size: 16px; font-weight: 400'">
              {{ this.contact.editedName || this.contact.name }}
            </div>
            <v-row class="d-flex justify-center">
              <v-col cols="6" md="1" sm="1" v-for="(tag, idx) in allTags" :key="idx">
                <span class="mr-1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon fab size="22px" v-bind="attrs" :color="tag.color" v-on="on">
                        {{ icons.mdiLabelVariant }}
                      </v-icon>
                    </template>
                    <span>Tag: {{ tag.name }}</span>
                  </v-tooltip>
                </span>
              </v-col>
            </v-row>
            <v-card-title :style="'color: black !important; font-size: 24px; font-weight: 500'"
              >Contact Detail</v-card-title
            >

            <v-divider class="mx-4" :style="'border: 1px solid  #999999 !important'"></v-divider>

            <v-card-text>
              <v-row align="center" class="mx-0">
                <div class="my-2">
                  <span class="grey--text"> Customer Phone: </span>
                  <v-list-item v-for="(phone, index) in this.contactPhones" :key="index">
                    <span class="black--text ms-4" :style="'font-size: 16px; color: black !important'">
                      {{ phone }}
                    </span>
                  </v-list-item>
                </div>
              </v-row>
              <v-row align="center" class="mx-0">
                <div class="my-2" v-if="contact.email">
                  <span class="grey--text"> Email: </span>
                  <v-list-item v-for="(email, index) in contact.email.email" :key="index">
                    <span class="black--text ms-4" :style="'font-size: 16px; color: black !important'">
                      {{ email }}
                    </span>
                  </v-list-item>
                </div>
                <div class="my-2" v-if="!contact.email">
                  <span class="grey--text"> Email: </span>
                  <span class="black--text" :style="'font-size: 16px; color: black !important'"> - </span>
                </div>
              </v-row>
              <v-row
                v-if="profileConfigAnswer.question1"
                v-for="(el, idx) in profileConfig"
                :key="idx"
                align="center"
                class="mx-0"
              >
                <div class="m-2">
                  <span class="grey--text">
                    {{ el.title ? el.title : el.name }}:
                    <span class="black--text" :style="'font-size: 16px; color: black !important'">{{
                      profileConfigAnswer[el.name]
                    }}</span></span
                  >
                </div>
              </v-row>
            </v-card-text>
            <!-- <v-card-actions>
              <v-btn color="primary" @click="editProfileConfig">
                {{ $t('Edit') }}
              </v-btn>
            </v-card-actions> -->
          </v-card>
          <div class="mt-10" :style="'font-size: 16px; color: black !important'">
            <span>List Room</span>
            <div
              class="mt-6 d-flex align-self-center"
              v-for="(room, idx) in allRooms"
              :key="idx"
              style="cursor: pointer"
              @click="moveToRoom(room)"
            >
              <v-avatar size="50px" class="my-auto align-center vac-avatar-frame">
                <v-img alt="Avatar" :src="room.profile_picture"></v-img>
              </v-avatar>
              <div>
                <div class="name-contact align-self-center">
                  <span> {{ room.name }} </span>
                  <span>
                    <span v-if="room.lastMessage" class="vac-text-date" :style="`color: grey; font-size: 12px;`">
                      {{ room.lastMessage.formattedTimestamp }}
                    </span>
                  </span>
                </div>
                <div
                  v-if="room.last_message"
                  class="align-self-center d-flex truncate"
                  :style="`color: grey; font-size: 12px;`"
                >
                  <span v-if="room.lastMessage.content !== ''"> {{ room.lastMessage.content }}</span>
                  <span v-if="room.lastMessage.data.type === 'image' || room.lastMessage.data.type === 'document'">{{
                    room.lastMessage.fileName
                  }}</span>
                  <span class="ml-100"> </span>
                </div>
                <v-row class="d-flex align-items-center">
                  <v-col>
                    <div class="d-flex align-items-center">
                      <div style="white-space: nowrap; flex-shrink: 0">
                        <span
                          class="room-contact-name font-weight-black me-1"
                          :style="`color: #FF6666; font-size: 12px;`"
                        >
                          {{ room.roomOwnerName }}
                        </span>
                      </div>

                      <div
                        v-for="(tag, idx) in room.contacts && room.expand.contacts.tags.length ? room.label : []"
                        :key="idx"
                        class="mr-1"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon fab size="15px" v-bind="attrs" :color="tag.color" v-on="on">
                              {{ icons.mdiLabelVariant }}
                            </v-icon>
                          </template>
                          <span>Tag: {{ tag.name }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-divider class="mx-5 m-15"></v-divider>
          </div>
        </div>
      </v-col>
      <!-- DATA PRIBADI & PEMESANAN -->
      <v-col cols="14" sm="4" md="8">
        <div>
          <iframe
            ref="iFrame"
            id="responseDetail"
            :src="`https://crm-survey.superwa.io/contact-detail`"
            width="100%"
            height="800"
            title="description"
            frameborder="0"
          >
          </iframe>
        </div>
      </v-col>
      <v-snackbar v-model="errorProfileValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
        Please make profile config first!
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorProfileValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="showToast" :color="snackbarColor" :top="true" style="z-index: 5 !important">
        {{ snackbarMessage }}
      </v-snackbar>
    </v-row>
    <dialogProfileConfigEdit
      ref="dialogProfileConfigEdit"
      @fetch-profile="profileConfigFetch"
      @validation-profile="validationProfileConfig"
    ></dialogProfileConfigEdit>
  </v-container>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { emailValidator, required } from '@core/utils/validation'
import { mdiArrowLeftThick, mdiDotsVertical, mdiLabelVariant, mdiPin } from '@mdi/js'
import { StylesManager } from 'survey-core'
import 'survey-core/defaultV2.min.css'
import { Survey } from 'survey-vue-ui'
import dialogProfileConfigEdit from '../component/DialogProfileConfigEdit.vue'

StylesManager.applyTheme('defaultV2')

export default {
  name: 'ContactDetail',
  components: {
    Survey,
    dialogProfileConfigEdit,
  },
  setup() {
    return {
      validators: {
        required,
        emailValidator,
      },
    }
  },
  data() {
    return {
      //snackbar data
      showToast: false,
      snackbarMessage: '',
      snackbarColor: 'success',

      options: 0,
      icons: {
        mdiLabelVariant,
        mdiPin,
        mdiDotsVertical,
        mdiArrowLeftThick,
      },
      attrs: {
        color: 'primary',
        dark: true,
      },
      contact: {
        id: '',
      },
      allResponse: [],
      formData: {},
      surveyJson: {
        elements: [],
      },
      surveyResponse: '',
      profileConfig: [],
      profileConfigAnswer: [],
      allRooms: [],
      allTags: [],
      errorProfileValidationSnackbar: false,
      contactPhones: [],
      instances: [],
    }
  },
  async mounted() {
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.instances = instances?.value || []
    this.fetchContacts()
    this.fetchAllResponse()
    // this.profileConfigFetch()
    window.addEventListener('message', event => {
      if (event.data.id) {
        this.saveEditResponse(event.data)
      }
    })
  },
  watch: {
    allResponse() {
      this.loadResponse()
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    // instances() {
    //   if (this.$store.getters['auth/getListInstance'].length !== 0) {
    //     return this.$store.getters['auth/getListInstance'].value
    //   }

    //   return []

    //   // return this.$store.getters['auth/getListInstance']
    // },
    defaultProfPic() {
      return `${process.env.VUE_APP_WA_API_URL}ppic/@whatsapp.net/2414214242414/a.jpg`
    },
  },
  methods: {
    showMessage(msg, status = 'success') {
      this.snackbarMessage = msg
      this.snackbarColor = status
      this.showToast = true
    },
    showSuccess(msg) {
      this.showMessage(msg, 'success')
    },
    showError(msg) {
      this.showMessage(msg, 'error')
    },
    validationProfileConfig() {
      this.errorProfileValidationSnackbar = true
    },
    async fetchContacts() {
      const getContact = await clientPocketBase.collection('crm_contacts').getList(1, 50, {
        filter: `id = "${this.$route.params.id}"`,
        expand: 'tags',
        $autoCancel: false,
      })
      this.contact = getContact.items[0]

      // const getTags = await clientPocketBase.collection('crm_contact_tags').getList(1, 50, {
      //   filter: `contacts = "${this.contact.id}"`,
      //   expand: 'tag',
      //   $autoCancel: false,
      // })

      this.allTags = this.contact.expand?.tags

      this.allRooms = []

      const phoneNum = await clientPocketBase.collection('crm_contact_phones').getList(1, 50, {
        filter: `contact_id = "${this.contact.id}"`,
        expand: 'contact_id',
        $autoCancel: false,
      })
      //console.log(phoneNum, 'ini c uy<<')
      let queryInstance
      for (let i = 0; i < this.instances.length; i++) {
        const instance = this.instances[i]
        if (!queryInstance) {
          queryInstance = `instance = '${instance.id}'`
        } else {
          queryInstance += `|| instance = '${instance.id}'`
        }
      }

      for (let i = 0; i < phoneNum.items.length; i++) {
        const eachPhoneNumber = phoneNum.items[i].phone_number

        const getRooms = await clientPocketBase.collection('crm_contact').getList(1, 50, {
          filter: `phone_number = "${eachPhoneNumber}" && (${queryInstance}) && broadcast_group = false && lastMessage != null`,
          expand: `contacts`,
          $autoCancel: false,
        })
        let vacIndex
        // console.log('sayang log', getRooms)

        for (let i = 0; i < getRooms.items.length; i++) {
          const el = getRooms.items[i]
          let label = []

          if (el.contacts && this.allTags) {
            for (let i = 0; i < this.allTags.length; i++) {
              const tag = this.allTags[i]

              if (tag.channels?.channel?.length == 0) {
                label.push(tag)
              } else {
                for (let j = 0; j < tag.channels.channel.length; j++) {
                  const channel = tag.channels.channel[j]
                  if (channel.id === el.instance || channel.id === el.instance.id) {
                    label.push(tag)
                  }
                }
              }
            }
            el.label = label
          }

          // console.log('sayang channel', channelTags, el.instance);
          // console.log('sayang log label', label)
          if (el.lastMessage?.timestamp?._seconds) {
            // console.log(room.lastMessage.timestamp._seconds, `KDKFKSDFKSKFSKDFKSDFK`)
            vacIndex = el.lastMessage.timestamp._seconds
            const format = this.$moment.unix(el.lastMessage.timestamp._seconds).isSame(this.$moment(), 'day')
              ? 'HH:mm'
              : 'DD/MM/YYYY'

            el.lastMessage.formattedTimestamp = this.$moment.unix(el.lastMessage.timestamp._seconds).format(format)
          }

          if (el.lastMessage?.msg) {
            vacIndex = el.lastMessage?.timestamp / 1000
            const format = this.$moment.unix(el.lastMessage?.timestamp / 1000).isSame(new Date(), 'day')
              ? 'HH:mm'
              : 'DD/MM/YYYY'

            el.lastMessage.formattedTimestamp = this.$moment.unix(el.lastMessage?.timestamp / 1000).format(format)
          }
          try {
            let foundInstance = await clientPocketBase.collection('crm_instances').getOne(el.instance, {
              expand: 'instance_label_name',
              $autoCancel: false,
            })
            const editedName = foundInstance.expand?.instance_label_name?.edited_name
            if (editedName) {
              foundInstance.label = editedName
              foundInstance.label_server = editedName
            }
            el.roomOwnerName = foundInstance.label
            console.log('NAME:' + JSON.stringify([el.roomOwnerName, editedName, foundInstance]))
          } catch (error) {
            console.log(error)
          }
        }

        this.allRooms.push(...getRooms.items)
        this.contactPhones.push(eachPhoneNumber)
      }
    },
    async fetchAllResponse() {
      try {
        const foundResponse = await clientPocketBase.collection('crm_form_response').getFullList(200, {
          filter: `contacts = '${this.$route.params.id}' && type = 'Response'`,
          $autoCancel: false,
          expand: 'responses, responses.form',
        })
        // console.log('sayang response', foundResponse)
        for (let i = 0; i < foundResponse.length; i++) {
          const response = foundResponse[i]
          response.expand.responses.sort((a, b) => {
            return new Date(b.created) - new Date(a.created)
          })
          for (let j = 0; j < response.expand.responses.length; j++) {
            const responseAnswers = response.expand.responses[j]

            let objTemp = {
              form: responseAnswers.expand.form,
              responseAnswers: responseAnswers.response,
              formId: responseAnswers.id,
            }
            this.allResponse.push(objTemp)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async loadResponse() {
      let surveyData = {
        answer: this.allResponse,
      }
      // console.log((surveyData, 'https://crm-survey.superwa.io')<<Vira survey')

      let elementResponse = document.getElementById('responseDetail')

      if (elementResponse) {
        const onElementResponseLoad = () => {
          elementResponse.removeEventListener('load', onElementResponseLoad)
          elementResponse.contentWindow.postMessage(surveyData, 'https://crm-survey.superwa.io')
        }
        elementResponse.addEventListener('load', onElementResponseLoad)
        setTimeout(() => {
          elementResponse.removeEventListener('load', onElementResponseLoad)
          elementResponse.contentWindow.postMessage(surveyData, 'https://crm-survey.superwa.io')
        }, 2000)
      }
    },
    async saveEditResponse(data) {
      // console.log(data, '<<vira data')
      try {
        const updatedResponse = await clientPocketBase.collection('crm_form_response_data').update(
          data.formId,
          {
            response: data.response_data,
          },
          { $autoCancel: false },
        )
        this.showSuccess('Response Saved')
        // await this.fetchAllResponse()
        // await this.loadResponse()
        // location.reload()
      } catch (error) {
        this.showError('Failed to save response, please check your connection and try again')
        console.log(error, 'error sayang')
      }
    },
    editProfileConfig() {
      this.$refs.dialogProfileConfigEdit.open()
    },
    async profileConfigFetch() {
      try {
        let foundForm = await clientPocketBase
          .collection('crm_contact_form')
          .getOne(this.$Md5Hash(`Profile-Config - ${this.user.sub_id}`))
          .then(res => {
            this.profileConfig = res.survey_json
          })
        let foundResponse = await clientPocketBase
          .collection('crm_form_response')
          .getOne(this.$Md5Hash(this.$route.params.id + '-' + this.user.sub_id))
          .then(res => {
            this.profileConfigAnswer = res.response_data
          })
      } catch (error) {
        console.log(error)
      }
    },
    closeDetail() {
      this.$router.push({ name: 'contacts' })
    },
    moveToRoom(room) {
      this.$router.push({ name: 'inbox', query: { roomId: room.roomId } })
      // //console.log(room, "ini sayang");
      // this.$emit("open-room", room)
    },
  },
}
</script>
<style scoped>
.heigth-dialog {
  max-height: 400px;
  overflow-y: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
@media only screen and (max-width: 578px) {
  .sm-display {
    display: none;
  }

  .last-served-image {
    display: none;
  }

  .last-served {
    font-size: 12px;
  }
}

@media only screen and (min-width: 578px) {
  .sm-display-mobile {
    display: none;
  }
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */
}

.img-avatar {
  width: 141.89px;
  height: 130px;
  margin-left: 109.15px;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-radius: 20px;
}
.colorline-chat {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.vac-avatar-frame {
  height: 51px;
  width: 52px;
  min-height: 53px;
  min-width: 52px;
  margin-right: 15px;
  margin-left: 5%;
  margin-bottom: -1px;
}

.name-contact {
  color: #111b21;
  font-weight: 400;
  font-size: 16px;
}
</style>
